<div class="second_header">
  <div class="back_button" routerLink="/home">←back</div>
  <div class="title_container">
    <h2>FAQ</h2>
  </div>
</div>

<h1>Sign up</h1>
<div class="sectionTitleBox">
  <div class="sectionLine"></div>
</div>
<div>To get started with Wuuush, open our webapp wuuush.com from device your browser and register your account for free. You can sign up directly on your iOS or Android device, as well as via desktop browser.</div>
<br>
<div>After you register, you will receive an email asking you to confirm your Wuuush account. If you don’t receive this confirmation email, check your spam folder or contact Wuuush support. Once you confirm your account, you will have access to Wuuush service.</div>
<br>

<h1>Payments & Refunds</h1>
<div class="sectionTitleBox">
  <div class="sectionLine"></div>
</div>
<div><b>Make a payment</b></div>
<br>
<div>You can make payment to Wuuush.com OÜ bank account EE277700771009714228 or via a card. Minimum amount is 5.00 euro. Payment desctiption you have to add your account email address.</div>
<br>
<div><b>Example</b></div>
<br>
<div class="listUser first">
  <div class="listItem profile"><b>Beneficiary's name</b></div>
  <div class="listItem value profile">Wuuush.com OÜ</div>
</div>
<div class="listUser first">
  <div class="listItem profile"><b>Beneficiary's account no</b></div>
  <div class="listItem value profile">EE277700771009714228</div>
</div>
<div class="listUser first">
  <div class="listItem profile"><b>Amount</b></div>
  <div class="listItem value profile">5.00 EUR. This is the minimum amount you can add to your account</div>
</div>
<div class="listUser first">
  <div class="listItem profile"><b>Payment description</b></div>
  <div class="listItem value profile">Your e-mail address. You can use [at] or (at) instead of @ symbol.</div>
</div>
<br>
<div><b>NB!</b> Bank transfer sender's name must match first name and last name of your Wuuush.com account.</div>
<br>
<div><b>NB!</b> Bank transfer's description must contain your e-mail which matches your account e-mail.</div>
<br>
<div>Payment via bank transfer is not visible immediately. We try to make it visible as soon as possible so be patient.</div>
<br>
<div><b>Manage and view your payment history</b></div>
<br>
<div>You can add credit and see all transactions.</div>
<br>
<div><b>Request a refund</b></div>
<ul>
  <li>You are eligible for a refund payment during 14 days after your initial purchase.</li>
  <br>
  <li>Be aware that cancelling the washing cycle does not initiate the refund process. Please contact customer support directly at support@wuuush.com using the same email address as your Wuuush.com account, and wait for confirmation.</li>
  <br>
  <li>Due to Privacy Policy, we will not be able to process your request if you write to us from a different email address.</li>
  <br>
  <li>Please do not delete your Wuuush.com account before the refund has been completed since it might delay the process.</li>
</ul>