import {APP_INITIALIZER, NgModule}            from '@angular/core';
import {BrowserModule}                        from '@angular/platform-browser';          
import {AuthService, 
        MachineService, 
        AdminTransaction,
        UserService,
        EverypayService,
        MessageService}                       from "./services";
import {AppRoutingModule}                     from './app-routing.module';
import {AppComponent}                         from './app.component';
import {BrowserAnimationsModule}              from '@angular/platform-browser/animations';
import {ReactiveFormsModule, FormsModule}     from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule}  from '@angular/common/http';
import {MaterialAppModule}                    from './material.module';
import {AuthInterceptor}                      from './interceptors/auth.interceptor';
import {ErrorInterceptor}                     from './interceptors/error.interceptor';
import {AfterLoginGuard, 
        BeforeLoginGuard,
        AdminGuard}                           from './guards'
import {LoginComponent, 
        RegisterComponent,
        EmailTakenValidator,
        HomeComponent, 
        WashComponent, 
        Kadaka_platform_01Component,
        Kadaka_platform_02Component,
        Kadaka_platform_03Component,
        Kadaka_platform_04Component,
        Testing_platform_05Component, 
        Laagna_platform_01Component,
        Laagna_platform_02Component,
        Laagna_platform_03Component,
        Laagna_platform_04Component,
        Laagna_platform_05Component,
        KadakaComponent,
        LaagnaComponent,
        OrdersComponent,
        UsersComponent,
        TransactionsComponent,
        HistoryComponent,
        ProfileComponent,
        ConfirmComponent,
        BuyCreditComponent,
        AboutComponent,
        FAQComponent,
        TermsConditionsComponent,
        PrivacyPolicyComponent,
        RequestResetComponent,
        ResponseResetComponent}                    from "./components"

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    WashComponent,
    KadakaComponent,
    LaagnaComponent,
    Kadaka_platform_01Component,
    Kadaka_platform_02Component,
    Kadaka_platform_03Component,
    Kadaka_platform_04Component,
    Laagna_platform_01Component,
    Laagna_platform_02Component,
    Laagna_platform_03Component,
    Laagna_platform_04Component,
    Laagna_platform_05Component,
    Testing_platform_05Component,
    OrdersComponent,
    UsersComponent,
    TransactionsComponent,
    HistoryComponent,
    ProfileComponent,
    ConfirmComponent,
    BuyCreditComponent,
    AboutComponent,
    FAQComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,        
    RequestResetComponent,
    ResponseResetComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialAppModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [
    AuthService,
    MachineService,
    AfterLoginGuard,
    BeforeLoginGuard,
    AdminGuard,
    AdminTransaction,
    UserService,
    EverypayService,
    EmailTakenValidator,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => {
        return () => authService.initialize();
      },
      deps: [AuthService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
