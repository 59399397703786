<div class="second_header">
  <div class="back_button" routerLink="/home">←back</div>
  <div class="title_container">
    <h2>Choose credit amount to buy</h2>
  </div>
</div>
<div *ngIf="paymentSuccess != null" class="buyCredit">
  <mat-icon *ngIf="paymentSuccess == true" class="payment success">check</mat-icon>
  <mat-icon *ngIf="paymentSuccess == false" class="payment failed">close</mat-icon>
</div>

<div *ngIf="paymentSuccess == true" class="buyCredit comment success">{{paymentMessage}}</div>
<div *ngIf="paymentSuccess == false" class="buyCredit comment failed">{{paymentMessage}}</div>

<button *ngIf="paymentSuccess != null" routerLink="/home" class="homeButton next">OK</button>

<div *ngIf="!amountSubmitted && !amountConfirmed" class="buyCredit comment">From 5€ to 100€.</div>

<div *ngIf = "(amountSubmitted && !amountConfirmed)" class="buyCredit">
  <h1>{{amount}}&euro;</h1>
</div>

<form
  *ngIf = "!amountSubmitted && !amountConfirmed"
  #ngForm="ngForm"
  [formGroup]="paymentForm"
  (ngSubmit)="onSubmit()"
  (keydown.enter)="$event.preventDefault()"
  class="inputForm"
>
  <mat-form-field 
                  
  [hideRequiredMarker]="true"
  floatLabel="never"
  >
  <input class = "input-amount"
      matInput
      type="number"
      name="amountField"
      id="amountField"
      formControlName="amountField"
      required
  >
  <mat-icon
      matSuffix
      *ngIf="amountField.touched || ngForm.submitted"
  >
      {{amountField.invalid && (amountField.dirty || amountField.touched || ngForm.submitted) ? 'close' : 'done'}}
  </mat-icon>
  <mat-error *ngIf="amountField.errors && amountField.errors.required">Please enter amount to pay.</mat-error>
  <mat-error *ngIf="amountField.errors && amountField.errors.min">Minimum amount is 5&euro; .</mat-error>
  <mat-error *ngIf="amountField.errors && amountField.errors.max">Maximum amount is 100&euro; .</mat-error>
  </mat-form-field>

</form>

  <div *ngIf = "!amountConfirmed" style="margin-top: 30px;">Payment methods:</div>
  <div *ngIf = "!amountConfirmed">
    <div class="listBoxCards" *ngFor="let card of paymentMethods; let i = index;">
    
      <input class="form-check-input" type="radio"  
      name="gob" value={{i}} [checked]="selectedPaymentMethod == i" 
      (change)="onItemChange($event.target.value)"/>

      <img *ngIf = "card.cardType == 'master_card'" src = "../../../assets/MasterCard_Logo.png" class = "logo">
      <img *ngIf = "card.cardType == 'visa'" src = "../../../assets/Visa_Logo.png" class = "logo">

      <div class = "cardData">
        <div class="cardUpperPart">
          <div class="cardType" *ngIf = "card.cardType == 'master_card'">Master Card</div>
          <div class="cardType" *ngIf = "card.cardType == 'visa'">Visa</div>
          <div class="cardName"> {{card.cardName}}</div>
        </div>
        <div class="cardLowerPart">
          <div class="cardNumber"><b>**** {{card.cardNumber}}</b></div>
          <div class="cardDate">{{card.cardDate}}</div>
        </div>
      </div>
      <mat-icon *ngIf = "!amountSubmitted" class="deleteCard" (click) ="initiateDeleteCard()" >delete_forever</mat-icon>
  </div>

  </div>

  <div *ngIf = "!amountConfirmed" class="listBoxCards" style="margin-bottom: 20px;">

    <input class="form-check-input" type="radio"  
    name="gob" value=99 [checked]="selectedPaymentMethod == 99" 
    (change)="onItemChange($event.target.value)"/>

    <div class="newCard"><i>+ Pay with new card</i></div>

  </div>

  <i style="font-size: 12px">NB! You have can only one card saved as payment method.</i>

<div *ngIf="TrustedEverypayURL != null" class="cardBoxOne">
  <div class = "cardOne">
    <mat-spinner diameter = 160></mat-spinner>
  </div>
</div>

<button *ngIf="!amountSubmitted && !amountConfirmed && !deleteCard" (click)="onSubmit()" class="homeButton next">Buy {{amountField.value}} &euro; credit</button>

<div *ngIf="amountSubmitted && !amountConfirmed" class="buyCredit confirmation" >Are you sure you want to buy &nbsp;<b>{{amount}} &euro;</b> &nbsp; credit  </div>
<div *ngIf="amountSubmitted && !amountConfirmed && selectedPaymentMethod == 99" class="buyCredit confirmation" >with &nbsp;<b>new card</b> ?</div>
<div *ngIf="amountSubmitted && !amountConfirmed && selectedPaymentMethod == 0" class="buyCredit confirmation" >with card &nbsp;<b>****{{paymentMethods[0].cardNumber}}</b> ?</div>
<div  *ngIf="amountSubmitted && !amountConfirmed" class="buyCredit buttons">
    <button class = "no" (click) = "cancelSubmit()">No</button>
    <button class = "yes" (click) = "onConfirm()">Yes</button>
</div>

<div *ngIf="deleteCard" class="buyCredit confirmation" >Are you sure you want to </div>
<div *ngIf="deleteCard" class="buyCredit confirmation" >delete card &nbsp;<b>****{{paymentMethods[0].cardNumber}}</b> ?</div>
<div  *ngIf="deleteCard" class="buyCredit buttons">
  <button class = "no" (click) = "cancelDeleteCard()">No</button>
  <button class = "yes" (click) = "confirmDeleteCard()">Yes</button>
</div>
