import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EverypayService } from 'src/app/services';

@Component({
  selector: 'app-everypay-landing',
  templateUrl: './everypay-landing.component.html',
})
export class EverypayLandingComponent implements OnInit {

  orderReference:string;
  paymentReference:string;
  result:string;

  constructor(
    private route: ActivatedRoute,
    protected everypayService: EverypayService,
  ) { }

  async ngOnInit() {
    this.orderReference = this.route.snapshot.queryParamMap.get('order_reference');
    this.paymentReference = this.route.snapshot.queryParamMap.get('payment_reference');
    const response =  await this.everypayService.chargeWithNewCard(this.paymentReference);
    parent.window.postMessage({ 
      orderReference:this.orderReference,
      paymentReference:this.paymentReference,
      paymentResponse:response
    },'*');
    console.log (response);
  }
}